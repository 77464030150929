import { mapGetters } from "vuex";
import {currencyPass, checkEventEnded} from '@/mixins/dateSupport.js';

//pure functions...
let isContentFree = (content={}) => {
  return (
    (content.pricemodel==='FREE') 
    || 
    (
      content.price &&
      Array.isArray(content.price) &&
      content.price.some((element) => parseInt(element.amount) === 0)
    )
  );

}

let isPreBook = (content) => {
  return (
    content && content.premieringstatus === "PREBOOK"
  );
}
let isAnnounced = (content) => {
  return (
    content && content.premieringstatus === "ANNOUNCED"
  );
}

//symbols... 
let content = Symbol('content');

//Mixin declaration... appMixin dependency
const purchaseMixins = {
  data() {
    return {
      [content]: {}
    }
  },
  computed: {
    ...mapGetters(["appConfig", "appUser", "ticketResponse", "plansSubscribedResponse",'country']),
    purchase(){
      return ( this.appConfig?.featureEnabled?.purchase && 
                     this.p_priceAvailable( this[ content ] )
                    );
    },
    subscription(){
      return ( this.appConfig?.featureEnabled?.subscription
              && ( this[ content ]?.pricemodel !== 'PREMIUM' )  
            );
    },
  },
  methods: {

    p_priceAvailable(content={}){
      return (    Array.isArray(content.price) && 
                  ( content.price.some(price=>currencyPass(price.currency,this.country)) )
              )       
    },
    p_isContentAvailable(){
      return (
        (this[content].price &&
        Array.isArray(this[content].price) &&
        this[content].price.some((element) => {
            return currencyPass(element.currency,this.country);
        })
        )||
        this.subscription
      );
    },  
    p_getPurchaseData(status) {
      let purchaseDetails, buttonDisplayDiv, isDisplayWatch, buttonName,watchable;
      if (isAnnounced(this[content]) || this.p_isExpired()) {
        return (this.buttonDisplayDiv = false);
      }
      switch (status) {
        case "watch":
          purchaseDetails = "purchased";
          buttonDisplayDiv = !isPreBook(this[content]);
          isDisplayWatch = true;
          buttonName = "WATCH";
          watchable=true;
          break;
        case "buy":
          purchaseDetails = "";
          buttonDisplayDiv = this.p_isContentAvailable();
          isDisplayWatch = false;
          buttonName = "BUY";
          watchable=false;
          break;
        case "free":
          purchaseDetails = "";
          buttonDisplayDiv = !isPreBook(this[content]);
          isDisplayWatch = true;
          buttonName = "WATCH";
          watchable=true;
          break;
        case "guest":
          purchaseDetails = "";
          buttonDisplayDiv = true;
          isDisplayWatch = false;
          watchable=false;

          if (isContentFree(this[content])) buttonName = "LOGIN";
          else {
            buttonName = !this.purchase && !this.subscription ? "LOGIN" : "BUY";
          }
      }
      return { purchaseDetails, buttonDisplayDiv, isDisplayWatch, buttonName, watchable }
    },
    p_isExpired() {
      if (this[content] && this[content].contenttype === "EVENT" && this[content].todate) {
        return checkEventEnded(this[content].todate)
      } else {
        return false
      }
    },
    p_isPurchased(contentdata) {
      if(contentdata)this[content]=contentdata;
      let purchasedData =
        this.ticketResponse &&
        this.ticketResponse.find(
          (element) => element.contentid === this[content].contentid
        );

      if (
        purchasedData &&
        purchasedData.status === "ACTIVE" &&
        !this.checkForContentExpiryDate(purchasedData["expiry"])//appMixin method...
      )
        return true;
      else return false;
    },
    p_isSubscribed() {
      let subscriberDetails = this.plansSubscribedResponse;
      if (subscriberDetails) {
        if (
          (subscriberDetails.planstatus === "ACTIVE" ||
            subscriberDetails.planstatus === "TRIAL" ||
            subscriberDetails.planstatus === "CANCEL") &&
          !this.checkForContentExpiryDate(subscriberDetails["expirydate"])//appMixin method
        ) return true;
        else return false;
      }
      else return false;
    },
    actPurchaseStatus(currentContent = {}) {

      this[content] = currentContent;
      if (!this.appConfig) return;

      if (!this.appUser) return this.p_getPurchaseData("guest");

      if (isContentFree(this[content])) return this.p_getPurchaseData("free");

    
      if ((this.subscription && this.p_isSubscribed()) ||
        (this.purchase && this.p_isPurchased())
      ) return this.p_getPurchaseData("watch");

      else if (!this.purchase && !this.subscription) {
        return this.p_getPurchaseData("watch");
      }
      else return this.p_getPurchaseData('buy');

    },



  }
}

export default purchaseMixins;