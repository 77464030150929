import store from "@/store";


const _tokenPromise = async (formData,tokenUrl) => {
 
  let response = await fetch(tokenUrl, {
    method: "POST",
    credentials: "include",
    body: formData
  });
  if (!response.ok) throw new Error(response.status);

  response = await response.json();
  if (response.reason) throw response

  return response.success;

}

function fetchToken(content) {
  if(!content.isDrmContent)return Promise.resolve();
  console.log('requesting token');
  const url=store.getters.appConfig.vendorDetails.baseUrl;
  let tokenUrl=`${url}/drm/v1/token`;
  let formData = new FormData();
  formData.append("contentid", content.contentId);
  

  return _tokenPromise(formData,tokenUrl)
    .catch(err => {
      console.log('trying auto login!',err);
      return store.dispatch('actUserAutoLogin')
                  .then(res => {
                      if (res) return _tokenPromise(formData,tokenUrl);
                      else throw err;
                  }).catch( () =>{
                    throw err ;
                  });
    });
}

export { fetchToken }