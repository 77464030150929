<template>
  <div class="premier-slider-wrapper" v-if="showSlide">
      <div class="content-background"></div> 
      <div class="premier-slider-controls" id="premier-controls">
          <div class="icon-prev">
              <img src="@/assets/icons/ico-prev.svg" alt="previous">
          </div>
          <div class="icon-next">
              <img src="@/assets/icons/ico-next.svg" alt="next">
          </div>
      </div>
      <div class="premier-slider" id="premier-car-slide"> 
          <div class="car-slide" v-for="(content,i) in contents" :key="i">           
             <premier-content :content="content" :observer='observer' :player='player'  />             
          </div>
      </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import {mapActions, mapGetters} from 'vuex';
import PremierContent from "./premierContent.vue";
import { Mainplayer, createPlayerContent } from 'video-player';
import { fetchToken } from '@/components/Player/tokenRequest';
export default {
    props: ["category"],
    data(){
        return {
            contents:[],
            showLoading:true,
            showSlide:false,
            pgRating:'UA',
            featuredTags:[],
            defaultImage: require("@/assets/icons/placeholder-landscape.png"),
            timerIds:[],
            player:null,
            observer:null,
            currentEntry:null
        }
    },
    computed:{
        ...mapGetters( [ 'playerInstance' ] )
    },
    watch:{
        'playerInstance.active':{
            handler( current ){
                console.log('ACTIVE', current)
                if( current ) this.player.active && this.player.videoController.pause();
                else this.player.active && this.player.videoController.play();
            }
        }
    },
    methods:{ 
        ...mapActions(["actListContents"]),
        fetchContents(counterIndex) {
            let params = this.category.parameters;
            this.showLoading = true;
            this.actListContents(params)
                .then((response) => {
                    this.showLoading = false;
                    if (!(response && response.length))return; 
                    this.contents = [...this.contents, ...response];
                    this.showSlide = true;
                    this.appendSlider();        
                })
                .catch((error) => {
                    this.showLoading = false;
                    if(!this.contents)this.showSlide = false;
                    console.log(error);
                });
        },
        appendSlider(){

            this.showSlide=true;
            this.slider?.destroy?.();
            this.$nextTick(() => {
                this.slider = tns({
                container: `#premier-car-slide`,
                controlsContainer: `#premier-controls`,
                mouseDrag: true,
                nav: false,
                loop: false,
                slideBy: 1,
                gutter: 15,
                preventScrollOnTouch: 'forced'
                });
            
            });
        },
        createIntersectionObserver(){
            const options={
                root:null,
                threshold:1.0
            }
            const callback=async (entries)=>{
                const currentEntry= entries.find(( entry ) => entry.isIntersecting )?.target;

                if(!currentEntry)return this.player.active && this.player.videoController.pause();

                if(currentEntry === this.currentEntry )return this.player.active && this.player.videoController.play();

                if(this.player.active) await this.player.destroy();

                this.currentEntry?.classList.remove('view-active');
                this.currentEntry=currentEntry;
                
                const contentid=this.currentEntry.dataset.contentid;
                const trailer=this.contents.find(content=>content.contentid === contentid).trailer?.[0];
                if(!trailer)return;

                const videoTag=currentEntry.querySelector('video');

                this.player.instantiate(videoTag);
                
                const content={
                    contentId:trailer.trailerid,
                    source:[trailer.url]
                }
                content.drmToken=await fetchToken(content);

                await this.player.loadContent(
                    createPlayerContent( content )
                )

                this.currentEntry.classList.add('view-active');

                this.player.videoController.toggleMute(true);
                this.player.videoController.play();
            }

            this.observer=new IntersectionObserver( callback, options);
        }
    },
    components:{PremierContent},
    created(){
        this.fetchContents();
        this.player=new Mainplayer();
        this.createIntersectionObserver();
    },
    beforeDestroy(){
        this.player.active && this.player.destroy();
        this.observer.disconnect();
    }
}
</script>

<style lang='scss' scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
.premier-slider-wrapper{
    position:relative;
    font-family: $font-family;
    margin:30px 0;
    .content-background{
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        height:100%;
        width:100vw;
        background:$clr-color-black-7;
    }
    .premier-slider-controls{
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        width: calc(var(--max-width) + var(--btn-space));
        height:100%;
        display:flex;
        flex-flow:row nowrap;
        justify-content: space-between;
        align-items: center;

        [class*='icon']{
            opacity: 0.6;
            &[aria-disabled='true']{
                opacity: 0.3;
            }
            img{
                display:block;
                cursor:pointer;
            }
        }

    }
    @include breakpoint($point:max768){
        margin-right:calc( ( 100% - var(--max-width) ));
        .premier-slider-controls{ display:none };
    }
    
}
</style>