<template>
   <div class="content-wrapper">
        <div class="content-details">
            <div class="upcoming-tag">
                <span>UPCOMING</span>    
            </div>
            <div class="content-title">{{content.title}}</div>
            <div class="start-timer" v-if="getStartDate" >{{getStartDate}}</div>
            <div class="featured-tags">
                <div class="feat-tag" v-for="(tags, key) in featuredTags" :key="key">
                    <span v-if="tags">{{ tags }}</span>
                </div>
                <div class="feat-tag bordered" v-if="pgRating">
                    <span>{{pgRating}}</span>
                </div>
            </div>
            <div class="content-description">
                {{content.description}}
            </div> 
            <div class="purchase-button-wrapper"
                v-if="(content.premieringstatus!=='ANNOUNCED') && !isSubscribed"
            >
                <button class="purchase-button vlt-btn" v-if="!contentFree" @click="actPurchase" :disabled="isPurchased">
                    {{isPurchased?'Booked':'Book now'}}
                </button> 
            </div>                   
        </div>
        <div class="content-poster">
            <div class="poster-wrapper" ref='posterParent' :data-contentid='content.contentid'>
                <img class="poster-image" loading="lazy" :src="getPoster" alt="poster">
                <!-- <button class="play-button" v-if="Array.isArray(content.trailer) && content.trailer.length" @click="playTrailer">
                    <img src="@/assets/icons/play-btn.svg" alt="play-icon">
                </button> -->
                <div class="video-wrapper" @click='togglePlay'>
                    <video class="video-tag" src='' tabindex="0" crossorigin ></video>
                    <div class="volume-icon">
                        <img :src="volumeIcon" alt="volume" @click.stop="toggleVolume">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import appMixins from '@/mixins/appMixins.js';
import { formatDateString } from '@/mixins/dateSupport';
import purchaseMixins from '@/mixins/purchaseMixins.js';
import { mapGetters, mapMutations} from "vuex";
import { EventBus } from "@/main";
export default {
    props:['content','observer', 'player'],
    data(){
        return {        
            volumeEnabledIcon:require('@/assets/icons/volume_enabled.svg'),
            volumeDisabledIcon:require('@/assets/icons/volume_disabled.svg')
        }

    },
    computed:{
        ...mapGetters(['subscriberId','appConfig']),
        pgRating(){
            return (this.content.pgrating && this.getPgRating(this.content.pgrating));
        },
        featuredTags(){
            return [...this.getFeaturedTags(this.content)];
        },
        contentFree(){
            return (
                        (this.content.pricemodel==='FREE') 
                        || 
                        (
                        this.content.price &&
                        Array.isArray(this.content.price) &&
                        this.content.price.some((element) => parseInt(element.amount) === 0)
                        )
                    );
        },
        getPoster(){
            let backImage=null;
            for(let poster of this.content.posters){
                if(!(poster.orientation === "LANDSCAPE"))continue;
                backImage=poster.url;
                if(poster.quality === "SD")break;
            }
            backImage=backImage??this.defaultImage;
            return backImage;
        },
        purchase(){
            return ( this.appConfig?.featureEnabled?.purchase && 
                        this.p_priceAvailable( this.content )
                        );
        },
        subscription(){
            return ( this.appConfig?.featureEnabled?.subscription
                && ( this.content?.pricemodel !== 'PREMIUM' )  
                );
        },
        isPurchased(){
            return this.p_isPurchased(this.content);
        },
        isSubscribed(){
            return this.p_isSubscribed();
        },
        getStartDate(){
            let fromDateString=this.content.fromdate;
            if(!fromDateString)return null;  
            let startDate = null;
    
            let currentDate = new Date();
            let fromDate = new Date( formatDateString( fromDateString ) );
            if (fromDate > currentDate) {   
                let day = fromDate.getDate()
                let month = fromDate.toLocaleString('default', { month: 'long' });
                startDate = 'Premiering on ' + month + ' ' + day ;   
            
            }

            return startDate;
       },
       volumeIcon(){
           return this.player.videoController?.muted?this.volumeDisabledIcon:this.volumeEnabledIcon;
       }
    },
    methods:{
        ...mapMutations(['commitCurrentContent']),
        // playTrailer(){
        //     const trailer=this.content.trailer[0];
        //     trailer.posters = this.content.posters ?? [];
        //     trailer.title = trailer.title || this.content.title; 
        //     EventBus.$emit("loadPlayer", trailer);
        // },
        actPurchase() {
            const content=this.content;
            if (!this.subscriberId) {
                this.commitCurrentContent(content);
                return EventBus.$emit("LoginPopup");
            } 
            let data = {};
            if (this.purchase && this.subscription) {
                data = {
                    intermediate: true,
                    content: content,
                };
            } else if (this.subscription) {
                data = {
                    subscription: true,
                    menuClicked: false,
                };
                this.backData = "subscription";
            } else if (this.purchase) {
                data = {
                    purchase: true,
                    content: content,
                };
                this.backData = "purchase";
            } else {
                data = {
                    payment: true,
                    content: content,
                    paymentdata: this.paymentInitData,
                };
            }
            EventBus.$emit("paymentInitPopup", data); 
       },
       toggleVolume(){
           this.player.videoController?.toggleMute();
       },
       togglePlay(){
           this.player.videoController.paused
                ?
                this.player.videoController.play()
                :
                this.player.videoController.pause();
       }
    },
    mounted(){
        this.observer.observe(this.$refs.posterParent);
    },
    beforeDestroy(){
        this.observer.unobserve(this.$refs.posterParent);
    },
    mixins:[appMixins,purchaseMixins],
}
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";
@import "~sass/modules/_mediaQueries.scss";
  .content-wrapper{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin:40px 5px;
        .content-details{
            flex:1;
            display:grid;
            grid-template-columns: 1fr;
            row-gap: 15px;
            .upcoming-tag{      
                font-weight: $font-weight-medium;
                font-size: 0.9rem !important;
                line-height: 1.5rem;
                span{
                    display:inline-block;
                    padding: 1px 10px;
                    color:$font-clr-white;
                    background:$tag-primary;
                    border-radius:0.5rem;
                }
               
    
            }
            .content-title {
                    font-size: 1.5rem;
                    color: $font-clr-white;
                    text-transform: capitalize;
                    font-weight: $font-weight-semibold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
            }
            .start-timer{
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: $font-weight-semibold;
                color: $clr-color-red;
                opacity:0.8;
            }
            .featured-tags{
                display:flex;
                flex-flow:row wrap;
                span{
                    color: $tag-primary;
                    text-transform: capitalize;
                    font-family: $font-family;
                    font-weight: $font-weight-regular;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-right:10px;
                }
                .bordered{
                    border:1px solid $tag-primary;
                    border-radius: 3px;
                    padding: 0px 5px;
                    span{margin:0;}
                }
            }
            .purchase-button-wrapper{
                .purchase-button{
                    width:150px;
                    padding:10px 16px;
                    border-radius:8px;
                    cursor:pointer;
                    &:enabled{
                        @include buttonHover;
                    }
                    &:disabled{
                        background-color:$font-clr-gray !important;
                        color:$font-clr-black;
                    }
                }
            }
        }
        .content-poster{
            width:40%;
            .poster-wrapper{
                position:relative;
                width:100%;
                height:0;
                padding:28.125% 0;
                overflow: hidden;
                border-radius:5px;
                box-shadow:0 0 8px rgb(0,0,0), 0 0 16px rgba(0,0,0,0.5);
                .poster-image, .video-wrapper, .video-tag{
                    position:absolute;
                    top:0;
                    left:0;
                    height:100%;
                    width:100%;
                }
                .video-wrapper{
                    display:none;
                    z-index:3;
                    cursor:pointer;
                    .video-tag{
                        background: rgb(0,0,0);
                        object-fit: contain;
                    }
                    .volume-icon{
                        position:absolute;
                        width:20px;
                        right:10px;
                        bottom:10px;
                        img{
                            display:block;
                            width:100%;
                            cursor:pointer;
                        }
                    }
                }
                &.view-active .video-wrapper{
                    display:block;
                }
                .play-button{
                    position: absolute;
                    z-index: 3;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    display: block;
                    background: none;
                    outline: none;
                    border: 0;
                    cursor:pointer;
                    img{
                        display: block;
                    }
                    &:hover{
                        transform:translate(-50%,-50%) scale(1.1);
                    }
                }
            }
        }
        @include breakpoint($point:max768){
            flex-flow:column nowrap;
            flex-direction: column-reverse;
            align-items: flex-start;
            .content-poster{
                width:100%;
            }
            .content-details{
                margin-top:20px;
            }
        }
    }
</style>